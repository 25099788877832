<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="true"
             :on-cancel="onCancel"
             :is-full-page="fullPage"></loading>

    <Header/>
    <div class="main_dashboard">



      <div class="sent_container mt-20">
        <div class="col-12 col-sm-12 mb-3">
          <div class="row">
            <div class="col-6 col-md-3">
              <h5 class="head-title mt-2">Invoice Create</h5>
            </div>

          </div>



        </div>


        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(DraftInvoice)" class="rowpt-70">
        <div class="row">

              <div class="col-md-9">
                <div class="card">

                  <div class="card-body p-4">
                    <div class="row justify-content-md-between">
                      <div class="col-md-4">

                      </div>

                      <div class="col-md-3 text-md-right">
                        <h2>Invoice #</h2>

                        <!-- Form Group -->

                          <ValidationProvider name="invoice_number" rules="required" v-slot="{ errors }">
                            <input type="text" class="lss-form-control " v-model="form.invoice_number" placeholder="Example: 0000001">
                            <span  class="text-danger">{{ errors[0] }}</span>
                          </ValidationProvider>

                        <!-- End Form Group -->


                      </div>
                    </div>
                    <!-- End Row -->


                    <div class="row">
                      <div class="col-md-6 mt-3">
                        <label>Sender</label>
                        <ValidationProvider name="sender" rules="required" v-slot="{ errors }">
                          <v-select   v-model="form.sender" class="lss-form-select" placeholder="Choose Sender" label="text" @input="LoadBank" :options="SenderData"></v-select>
                          <span  class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>

                      </div>

                      <div class="col-md-6 mt-3">
                        <label>Reciver</label>

                        <ValidationProvider name="reciver" rules="required" v-slot="{ errors }">
                          <v-select v-model="form.reciver" class="lss-form-select" placeholder="Choose Reciver" label="text" :options="ClientData"></v-select>
                          <span  class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>

                      </div>
                      <div class="col-md-6 mt-3">
                        <label>Sender Bank</label>
                        <ValidationProvider name="bank" rules="required" v-slot="{ errors }">

                          <v-select v-model="form.bank" class="lss-form-select" placeholder="Choose Bank" label="text" :options="BankData"></v-select>
                          <span  class="text-danger">{{ errors[0] }}</span>

                        </ValidationProvider>

                      </div>
                      <div class="col-md-6 mt-3"></div>

                      <div class="col-md-6 mt-3">
                        <label >Create date</label>
                        <ValidationProvider name="created_at" rules="required" v-slot="{ errors }">
                          <b-form-datepicker  v-model="form.created_at" class="lss-form-control mb-2"></b-form-datepicker>
                          <span  class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>

                      <div class="col-md-6 mt-3">
                        <label >Payment date</label>
                        <ValidationProvider name="payment_at" rules="required" v-slot="{ errors }">
                          <b-form-datepicker  v-model="form.payment_at" class="lss-form-control mb-2"></b-form-datepicker>
                          <span  class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>

                      <div class="col-md-6 mt-3">
                          <label>Currency</label>
                        <ValidationProvider name="currency" rules="required" v-slot="{ errors }">
                          <v-select v-model="form.currency" class="lss-form-select" placeholder="Choose Currency"  :options="['usd', 'gel','euro']"></v-select>
                          <span  class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>

                      <div class="col-md-6 mt-3">
                        <label>Repeat</label>
                        <ValidationProvider name="repeat" rules="required" v-slot="{ errors }">
                          <v-select v-model="form.repeat" class="lss-form-select" placeholder="Choose Repeat" :options="RepeatListData"></v-select>
                          <span  class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>


                    </div>



                    <hr class="my-5">

                    <table class="custom-table-bordered mt-3">
                      <thead class="custom-thead-light">
                      <tr>
                          <th width="300">Product</th>
                          <th>Price</th>
                          <th>Quantity</th>
                          <th class="text-center">Vat</th>
                          <th width="100" class="text-center">Sum</th>
                          <th width="100" class="text-center"></th>
                      </tr>
                      </thead>
                      <tbody>

                        <tr v-for="(item,key) in form.products">
                          <td>

                            <input type="text" v-model="item.name" class="lss-form-control">

                          </td>
                          <td>
                            <input type="number"  v-model="item.price" min="0" class="lss-form-control">
                          </td>
                          <td>
                            <input type="number"  v-model="item.qty"  min="0" class="lss-form-control">
                          </td>
                          <td>
                            18%
                          </td>
                          <td class="text-center" v-if="(item.qty*item.price)>0">
                            {{ Number(item.qty * item.price).toLocaleString() }}
                            <b v-if="form.currency==='gel'">₾</b>
                            <b v-else-if="form.currency==='usd'">$</b>
                            <b v-else-if="form.currency==='euro'">€</b>
                          </td>
                          <td class="text-center p-2" v-else>
                            0 ₾
                          </td>
                          <td class=" text-center">
                           <div v-on:click="RemoveItem(key)" class="pointer">
                              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                <path id="ic_clear_24px" d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z" transform="translate(-5 -5)"/>
                              </svg>

                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>


                    <div class="row">
                      <div class="col-md-12">
                          <div class="col-md-3 float-end mt-4">
                            <button class="btn btn-sent-yellow-lg-wd" v-on:click="AddProducts">Add Item</button>
                          </div>
                      </div>
                    </div>





                    <div class="row mt-3">
                      <div class="col-md-12">
                        <label>Comment</label>
                        <textarea class="lss-form-control" v-model="form.comment"></textarea>
                      </div>
                    </div>


                  </div>


                </div>
              </div>

              <div class="col-md-3">
                <div class="card">

                  <div class="card-body p-4">
                    <div class="row">




                      <div class="col-md-12 mb-3" >

                        <button class="btn-sent-yellow-lg-wd" :disabled="isSavedLoading">
                          <div v-if="isSavedLoading" class="loadingio-spinner-rolling-ud5ejxirre"><div class="ldio-2ts2g6f7w1k">
                            <div></div>
                          </div></div>
                          <span v-if="!isSavedLoading">Save</span>
                        </button>
                      </div>


                    </div>




                  </div>
                </div>
              </div>


        </div>
          </form>
        </ValidationObserver>

      </div>





    </div>



  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import Header from "@/components/Header";
import axios from "axios";



export default {
  name: "Create",
  data() {
    return {
      isLoading: false,
      isSavedLoading:false,
      status: '',
      fullPage: true,
      form:{
        created_at:'',
        payment_at:'',
        reciver:'',
        sender:'',
        currency:'',
        invoice_number:'',
        comment:'',
        repeat:'',
        products:[{
          name: '',
          qty: '',
          price: '',
          sum: '',
        }]
      },
      ClientData:[],
      SenderData:[],
      RepeatListData:[],
      BankData:[]


    }
  },
  components: {
    Loading,
    Header,

  },
  methods:{

    onCancel() {
    },
    getClients(){

      this.isLoading = true;
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.get(process.env.VUE_APP_BACKEND_URL+'/auth/client/get_options_all_clients',{
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(response => {
        this.isLoading = false;
        this.ClientData = response.data;
      });
    },
    getSenders(){

      this.isLoading = true;
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.get(process.env.VUE_APP_BACKEND_URL+'/auth/client/get_options_all_sender',{
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then(response => {
            this.isLoading = false;
            this.SenderData = response.data;
          });
    },
    getRepeatList(){
      this.isLoading = true;
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.get(process.env.VUE_APP_BACKEND_URL+'/auth/invoices/repeat_list',{
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then(response => {
            this.isLoading = false;
            this.RepeatListData = response.data;
          });
    },
    AddProducts(){

      this.form.products.push({
        name: this.form.products.name,
        qty: this.form.products.qty,
        price: this.form.products.price,
        sum:0
      })


    },
    RemoveItem(index){
      this.form.products.splice(index, 1);
    },
    DraftInvoice(){
      this.isSavedLoading = true;
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.post(process.env.VUE_APP_BACKEND_URL+'/auth/invoices/save_invoice',{data:this.form},{
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then(response => {
            this.isSavedLoading=false;
            this.$router.push('/Invoices/'+response.data.id+'/edit');
          }).catch((error) => {
            this.isLoading = false;
         });
    },
    LoadBank(){
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.get(process.env.VUE_APP_BACKEND_URL+'/auth/invoices/load_bank/'+this.form.sender.id,{
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then(response => {
            this.BankData=response.data;
          }).catch((error) => {

      });
    },
    getLastInvoiceNumber(){
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.get(process.env.VUE_APP_BACKEND_URL+'/auth/invoices/get_last_invoice_number',{
        headers: {
          'Authorization': `Bearer ${token}`
        }
          })
      .then(response => {
        this.isLoading = false;
        this.form.invoice_number = response.data;
      });
    }



  },

  mounted() {
    this.getLastInvoiceNumber();
    this.getRepeatList();
    this.getClients();
    this.getSenders();
    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false
    }, 300)
  }
}
</script>

<style scoped>


@keyframes ldio-2ts2g6f7w1k {
  0% { transform: translate(-50%,-50%) rotate(0deg); }
  100% { transform: translate(-50%,-50%) rotate(360deg); }
}
.ldio-2ts2g6f7w1k div {
  position: absolute;
  width: 47px;
  height: 47px;
  border: 10px solid #0a0a0a;
  border-top-color: transparent;
  border-radius: 50%;
}
.ldio-2ts2g6f7w1k div {
  animation: ldio-2ts2g6f7w1k 1s linear infinite;
  top: 56px;
  left: 50px
}
.loadingio-spinner-rolling-ud5ejxirre {
  width: 28px;
  height: 28px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-2ts2g6f7w1k {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.28);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-2ts2g6f7w1k div { box-sizing: content-box; }
/* generated by https://loading.io/ */
</style>