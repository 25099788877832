<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="true"

             :is-full-page="fullPage"></loading>

    <Header/>
    <div class="main_dashboard">
      <div class="billing">
        <div class="dashboard-head-line">
          <div class="sent_container">
            <div class="row align-items-center">
              <div class="col-12 mt-20">
                <h5 class="head-title">Packages</h5>
              </div>
            </div>
          </div>
        </div>

        <div class="sent_container mt-20">

              <div class="row mt-3">
                <div class="col-md-12">
                  <div class="alert alert-danger text-center">Your Package was expired. Please Renew Package.</div>
                </div>

                <div  v-for="(post, index) in ResponsePackagesData" :key="index" class="col-12 col-md-4">
                  <!-- Card -->


                  <div  class="card custom-card-checked" @click.stop.prevent="ChangeBox(post.id)" v-bind:class="{ checked:isActive==post.id}">
                    <div class="card-header bg-transparent text-center border-bottom rounded-0 border-0 py-4">
                      <input class="custom-checkbox-card-input" type="radio" id="billingPricingRadio1" name="billingPricingRadio">
                      <label class="billing-plan billing-plan-label" for="billingPricingRadio1">
                      </label>
                      <h6 class="card-subtitle mb-2">{{ post.package_name }}</h6>
                      <div class="mb-3">
                        <div class="card-title display-4">
                          {{ post.price }} ₾
                        </div>
                      </div>

                    </div>
                    <div class="card-body mt-3" v-html="post.desc">

                    </div>
                    <div class="card-body-strecth">
                      <button class="btn-sent-yellow-lg">Order now</button>
                    </div>
                    <div class="card-footer bg-transparent card-text small py-3">
                      <i class="fas fa-info-circle text-secondary"></i>
                      <a href="#" class="text-muted text-decoration-none">
                        Terms & Condition
                      </a>
                    </div>
                  </div>
                  <!-- End Card -->
                </div>

              </div>

        </div>

        <div class="sent_container mt-20">
          <div class="card">
            <div class="card-header bg-white py-3">
              <h5 class="card-header-title mb-0">Transaction History</h5>
            </div>
            <div class="card-body p-0">
              <table class="custom-table-bordered">
                <thead class="custom-thead-light">
                <tr class="text-center">
                  <th scope="col">Orders #</th>
                  <th scope="col">Type</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Date</th>
                  <th scope="col">Attachment</th>
                </tr>
                </thead>
                <tbody>
                <tr class="text-center">
                  <th scope="row">#20210101</th>
                  <td>1 წლიანი</td>
                  <td>100₾</td>
                  <td>2021/03/12</td>
                  <td><a href="" type="button" class="pdf_download"><i class="fas fa-download"></i> PDF</a></td>

                </tr>
                </tbody>
              </table>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import Header from "@/components/Header";
import axios from "axios";
export default {
  name: "Billing",
  data() {
    return {
      isLoading: false,
      fullPage: true,
      isActive: 1,
      name:'',
      ResponsePackagesData:[]
    }
  },
  components: {
    Loading,
    Header
  },
  methods:{
      ChangeBox(i){

        this.isActive=i;
      },
      LoadPackages(){
        this.isLoading = true;
        const token = JSON.parse(localStorage.getItem('user')).access_token;
        axios.get(process.env.VUE_APP_BACKEND_URL+'/auth/packages/load_packages',{
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
            .then(response => {
              this.isLoading = false;
              this.ResponsePackagesData=response.data;

            });
      },
      getCheckedPackage(){
        this.isLoading = true;
        const token = JSON.parse(localStorage.getItem('user')).access_token;
        axios.get(process.env.VUE_APP_BACKEND_URL+'/auth/packages/get_checked_package',{
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
            .then(response => {
              this.isLoading = false;
              this.isActive=response.data.package_id;

            });
      }
  },
  mounted() {

    this.LoadPackages();
    this.getCheckedPackage();
    this.isLoading = true;
       setTimeout(() => {
          this.isLoading = false
        }, 300)
  }
}
</script>

<style scoped>

</style>