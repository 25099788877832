<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="true"
             :is-full-page="fullPage"></loading>

    <Header/>
    <div class="main_dashboard">

      <div class="sent_container mt-20">
        <div class="col-12 col-sm-12 mb-3">
          <div class="row">
            <div class="col-6 col-md-3">
              <h5 class="head-title mt-2">Create Client</h5>
            </div>

          </div>


          <div class="card mt-4">

            <ValidationObserver v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(submitForm)" class="rowpt-70">
              <div class="row p-4">
                <div class="col-md-6">
                  <label>Name</label>
                  <ValidationProvider name="name" rules="required" v-slot="{ errors }">
                  <input type="text" v-model="form.name" class="lss-form-control" placeholder="Name">
                    <span  class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>

                <div class="col-md-6">
                  <label>Taxcode</label>
                  <ValidationProvider name="taxcode" rules="required" v-slot="{ errors }">
                    <input type="text" v-model="form.taxcode" class="lss-form-control" placeholder="Tax code">
                    <span  class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>


                <div class="col-md-6 mt-3">
                  <label>Email</label>
                  <ValidationProvider name="email" rules="required" v-slot="{ errors }">
                    <input type="text" v-model="form.email" class="lss-form-control" placeholder="Email">
                    <span  class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>

                <div class="col-md-6 mt-3">
                  <label>Mobile</label>
                  <ValidationProvider name="mobile" rules="required" v-slot="{ errors }">
                    <input type="text" class="lss-form-control" v-model="form.mobile" placeholder="Mobile">
                    <span  class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>


                <div class="col-md-6 mt-3">
                  <label>Director Name</label>
                  <ValidationProvider name="director_name" rules="required" v-slot="{ errors }">
                    <input type="text" class="lss-form-control" v-model="form.director_name" placeholder="Director Name">
                    <span  class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>


                <div class="col-md-6 mt-3">
                  <label>Address</label>
                  <ValidationProvider name="address" rules="required" v-slot="{ errors }">
                    <input type="text" class="lss-form-control" v-model="form.address" placeholder="Address">
                    <span  class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div class="col-md-12 mt-3">
                  <label>Logo Upload</label>
                  <vue-dropzone ref="myVueDropzoneLogo"  id="dropzone-logo" :headers="dropzoneLogoOptions.headers" v-on:vdropzone-success="uploadLogoSuccess" :options="dropzoneLogoOptions"></vue-dropzone>

                </div>
                <div class="col-md-3 mt-3">
                  <label>Uploaded File</label>
                  <img :src="form.logo_path"  class="img-fluid border">
                </div>

                <div class="col-md-12 mt-3">
                  <label>Signiture Upload</label>
                  <vue-dropzone ref="myVueDropzoneSigniture"  id="dropzone-signiture" :headers="dropzoneSignitureOptions.headers" v-on:vdropzone-success="uploadSignitureSuccess" :options="dropzoneSignitureOptions"></vue-dropzone>

                </div>
                <div class="col-md-3 mt-3">
                  <label>Uploaded File</label>
                  <img :src="signiture_base" class="img-fluid border">
                </div>




                <div class="col-md-12 mt-3 text-end">
                  <button class="btn-sent-yellow-lg">+ Save</button>
                </div>


              </div>
            </form>
            </ValidationObserver>

          </div>


        </div>





      </div>

    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import Header from "@/components/Header";
import axios from "axios";
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import vue2Dropzone from 'vue2-dropzone'
export default {
  name: "DetailsEdit",
  data() {
    return {
      isLoading: false,
      fullPage: true,
      error:false,
      errors:{},
      form:{
        name:'',
        taxcode:'',
        email:'',
        mobile:'',
        director_name:'',
        address:'',
        logo:'',
        signiture:''
      },
      signiture_base:'',
      dropzoneLogoOptions: {

        url: process.env.VUE_APP_BACKEND_URL+'/auth/details/upload_logo',
        headers: {
          'Cache-Control': null,
          'X-Requested-With': null,
          'authorization': `Bearer ${JSON.parse(localStorage.getItem('user')).access_token}`
        },
        thumbnailWidth: 200,
        addRemoveLinks: true,

      },
      dropzoneSignitureOptions: {

        url: process.env.VUE_APP_BACKEND_URL+'/auth/details/upload_signiture',
        headers: {
          'Cache-Control': null,
          'X-Requested-With': null,
          'authorization': `Bearer ${JSON.parse(localStorage.getItem('user')).access_token}`
        },
        thumbnailWidth: 200,
        addRemoveLinks: true,

      },

    }
  },
  components: {
    Loading,
    Header,
    vueDropzone: vue2Dropzone
  },
  methods: {

    submitForm(){
      this.isLoading = true;
      const token = JSON.parse(localStorage.getItem('user')).access_token;

      axios.put(process.env.VUE_APP_BACKEND_URL+'/auth/details/update_detail/'+this.$route.params.id,{'data':this.form}, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then((res) => {
            this.isLoading = false;
            this.$router.push('/Details');
          })
          .catch((error) => {
            this.isLoading = false;


          });

    },
    onFailure(message){
      this.error=true;
    },
    setErrors(errors){
      this.errors=errors;
    },
    hasError(fieldName){
      return (fieldName in this.errors);
    },
    clearError(event){
      delete this.errors[event.target.name]
    },
    uploadLogoSuccess: function(file, response) {
      this.form.logo=response.success;
    },
    uploadSignitureSuccess: function(file, response) {
      this.form.signiture=response.success;
    },
    getDataById(){
      this.isLoading = true;
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.get(process.env.VUE_APP_BACKEND_URL+'/auth/details/get_by_id/'+this.$route.params.id, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then((res) => {
            this.isLoading = false;
            this.form=res.data;
          })
          .catch((error) => {
            this.isLoading = false;


          });
    },
    getDataSignitureById(){
      this.isLoading = true;
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.get(process.env.VUE_APP_BACKEND_URL+'/auth/details/signiture_get_by_id/'+this.$route.params.id, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then((res) => {
            this.isLoading = false;
            this.signiture_base=res.data;
          })
          .catch((error) => {
            this.isLoading = false;


          });
    }


  },
  mounted() {
    this.getDataById();
    this.getDataSignitureById();
    this.isLoading = true;

  }
}
</script>

<style scoped>

</style>