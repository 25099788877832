<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="true"
             :on-cancel="onCancel"
             :is-full-page="fullPage"></loading>

    <Header/>
    <div class="main_dashboard">



      <div class="sent_container mt-20">
        <div class="col-12 col-sm-12 mb-3">
          <div class="row">
            <div class="col-6 col-md-3">
              <h5 class="head-title mt-2">Invoices</h5>
            </div>
            <div class="col-12 col-md-12 mt-3">
              <div class="row">
                <div class="col-md-3">



<!--                  <input type="text" class="lss-form-control" v-on:keyup="getFilter" v-model="keyword" placeholder="Search..." aria-label="Search">-->

                  <b-button variant="light" v-b-toggle href="#filter" @click.prevent>
                    <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><g><path d="M0,0h24 M24,24H0" fill="none"/><path d="M4.25,5.61C6.27,8.2,10,13,10,13v6c0,0.55,0.45,1,1,1h2c0.55,0,1-0.45,1-1v-6c0,0,3.72-4.8,5.74-7.39 C20.25,4.95,19.78,4,18.95,4H5.04C4.21,4,3.74,4.95,4.25,5.61z"/><path d="M0,0h24v24H0V0z" fill="none"/></g></svg>
                  </b-button>

                  <b-sidebar id="filter" title="Filter" shadow>



                    <div class="container">
                    <div class="row">
                      <div class="col-md-12 p-2">
                        <label>Invoice number</label>
                        <input type="text" v-model="form.invoice_number" class="lss-form-control" placeholder="Invoice number">
                      </div>
                      <div class="col-md-12 p-2">
                        <label>Sender</label>
                        <v-select v-model="form.sender" class="lss-form-select" placeholder="Choose Sender" label="text" :options="SenderData"></v-select>
                      </div>


                      <div class="col-md-12 p-2">
                        <label>Reciver</label>
                        <v-select v-model="form.reciver" class="lss-form-select" placeholder="Choose Sender" label="text" :options="ClientData"></v-select>
                      </div>



                      <div class="col-md-12 p-2">
                        <label>Amount</label>
                        <input type="number" min="0" class="lss-form-control" v-model="form.amount" placeholder="Amount">
                      </div>

                      <div class="col-md-6 p-2">
                        <button class="btn-sent-red-lg-wd" v-on:click="Clear">Clear</button>
                      </div>
                      <div class="col-md-6 p-2">
                        <b-button class="btn-sent-yellow-lg-wd"  v-b-toggle.filter v-on:click="Filter">Search</b-button>
                      </div>

                    </div>
                    </div>





                  </b-sidebar>

                </div>
                <div class="col-md-9 text-end">
                  <a href="/Invoices/create">
                    <button class="btn-sent-yellow-lg">+ Add new</button>
                  </a>
                </div>
              </div>

            </div>
          </div>


        </div>
        <table class="custom-table-bordered">
          <thead class="custom-thead-light">
          <tr class="text-center">
            <th scope="col">Status</th>
            <th scope="col">#</th>

            <th scope="col" class="text-start">Invoice Number</th>
            <th scope="col" width="250" class="text-start">Sender Company</th>
            <th scope="col" width="250" class="text-start">Recipient Company</th>
            <th scope="col" class="text-start">Amount</th>
            <th scope="col"  class="text-start">Paid</th>
            <th scope="col"  class="text-center">Payment type</th>
            <th class="col dc-none" scope="col">Created at</th>

            <th class="col" scope="col">Action</th>
          </tr>
          </thead>
          <tbody>
          <tr class="text-center" v-for="post in ResponseData.data" :key="post.id">
            <th scope="row">
              <span class="badge bg-danger" v-if="post.sum_money!=post.payment_money">Pending</span>
              <span class="badge bg-success" v-else>Paid</span>
            </th>
            <th scope="row">

              {{ post.id }}
            </th>

            <td class=" text-start">
              {{ post.invoice_number }}
            </td>
            <td class=" text-start">
              {{ post.detail.name }}<br>
              {{ post.detail.taxcode }}
            </td>
            <td class=" text-start">
              {{ post.client.name }}<br>
              {{ post.client.tax_code }}
            </td>
            <td class=" text-start">
              {{ Number(post.sum_money).toLocaleString() }}
              <span v-if="post.currency=='usd'">$</span>
              <span v-if="post.currency=='gel'">GEL</span>
              <span v-if="post.currency=='euro'">Euro</span>
            </td>
            <td class=" text-start">
              {{  Number(post.payment_money).toLocaleString() }}
              <span v-if="post.currency=='usd'">$</span>
              <span v-if="post.currency=='gel'">GEL</span>
              <span v-if="post.currency=='euro'">Euro</span>
            </td>
            <td class=" text-center">
                <span v-if="post.repeat_id=='1'">Yearly</span>
                <span v-if="post.repeat_id=='2'">Monthly</span>
                <span v-if="post.repeat_id=='3'">6 month</span>
                <span v-if="post.repeat_id=='4'">3 month</span>
                <span v-if="post.repeat_id=='5'">Once</span>
            </td>

            <td class="dc-none">{{ post.created_at }}</td>

            <td>



              <b-dropdown id="dropdown-aria" variant="default" class="m-2">

                <template #button-content>
                  <svg id="Component_4_1" data-name="Component 4 – 1" xmlns="http://www.w3.org/2000/svg" width="5" height="23" viewBox="0 0 5 23">
                    <circle id="Ellipse_7" data-name="Ellipse 7" cx="2.5" cy="2.5" r="2.5" fill="#c4c4c4"/>
                    <circle id="Ellipse_8" data-name="Ellipse 8" cx="2.5" cy="2.5" r="2.5" transform="translate(0 9)" fill="#c4c4c4"/>
                    <circle id="Ellipse_9" data-name="Ellipse 9" cx="2.5" cy="2.5" r="2.5" transform="translate(0 18)" fill="#c4c4c4"/>
                  </svg>
                </template>


                <b-dropdown-header id="dropdown-header-1">Groups</b-dropdown-header>
                <b-dropdown-item-button aria-describedby="dropdown-header-1" v-on:click="EditInvoice(post.id)">Edit</b-dropdown-item-button>
                <b-dropdown-item-button aria-describedby="dropdown-header-1" v-on:click="DeleteInvoice(post.id)">Delete</b-dropdown-item-button>
                <b-dropdown-item-button aria-describedby="dropdown-header-1" v-on:click="ArchiveInvoice(post.id)">Archive</b-dropdown-item-button>
              </b-dropdown>

            </td>

          </tr>

          </tbody>
        </table>

        <div class="row mt-2">
          <div class="col-md-3">
            <pagination v-if="isFilter" :data="ResponseData" @pagination-change-page="Filter"></pagination>
            <pagination v-if="!isFilter" :data="ResponseData" @pagination-change-page="getResults"></pagination>
          </div>
        </div>


      </div>



    </div>



  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import Header from "@/components/Header";
import axios from "axios";

export default {
  name: "index",
  data() {
    return {
      isLoading: false,
      fullPage: true,
      isFilter:false,
      ResponseData:{},
      ClientData:[],
      SenderData:[],
      form:{
        sender:'',
        reciver:'',
        invoice_number:'',
        reciver_taxcode:'',
        sender_taxcode:'',
        amount:''
      }
    }
  },
  components: {
    Loading,
    Header
  },
  methods:{

    onCancel() {
    },
    EditInvoice(id){
      this.$router.push({ name: 'InvoiceEdit', params: { id: id  } })
    },
    getResults(page = 1){
      this.isLoading = true;
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.get(process.env.VUE_APP_BACKEND_URL+'/auth/invoices/get_all_invoices?page=' + page+'&keyword='+this.keyword,{
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then(response => {
            this.isLoading = false;
            this.ResponseData = response.data;
          });
    },
    DeleteInvoice(id){
      this.isLoading = true;
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.delete(process.env.VUE_APP_BACKEND_URL+'/auth/invoices/delete_invoice_by_id/'+id,{
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then(response => {
            this.isLoading = false;
            location.reload();
          });
    },
    getClients(){

      this.isLoading = true;
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.get(process.env.VUE_APP_BACKEND_URL+'/auth/client/get_options_all_clients',{
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then(response => {
            this.isLoading = false;
            this.ClientData = response.data;
          });
    },
    getSenders(){

      this.isLoading = true;
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.get(process.env.VUE_APP_BACKEND_URL+'/auth/client/get_options_all_sender',{
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then(response => {
            this.isLoading = false;
            this.SenderData = response.data;
          });
    },
    Filter(page = 1){

      this.isFilter=true;
      this.isLoading = true;
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.get(process.env.VUE_APP_BACKEND_URL+'/auth/invoices/get_all_invoices?',{
        headers: {
          'Authorization': `Bearer ${token}`
        },
        params: {
          page:page,
          invoice_number:this.form.invoice_number,
          sender:this.form.sender.id,
          sender_taxcode:this.form.sender_taxcode,
          reciver:this.form.reciver.id,
          reciver_taxcode:this.form.reciver_taxcode,
          amount:this.form.amount
        }
      })
          .then(response => {
            this.isLoading = false;
            this.ResponseData = response.data;
          });




    },
    Clear(){
      this.isFilter=false;
      location.reload();
    },
    ArchiveInvoice(id){
      this.isLoading = true;
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.post(process.env.VUE_APP_BACKEND_URL+'/auth/invoices/archive_invoice_by_id',{
        id:id
      },{
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then(response => {
            this.isLoading = false;
            this.getResults();
          });
    }



  },

  mounted() {
    this.getClients();
    this.getSenders();
    this.getResults();
    this.isLoading = true;
/*    setTimeout(() => {
      this.isLoading = false
    }, 300)*/
  }
}
</script>

<style scoped>

</style>