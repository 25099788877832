<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="true"
             :on-cancel="onCancel"
             :is-full-page="fullPage"></loading>

    <Header/>
    <div class="main_dashboard">

      <div class="sent_container mt-20">
        <div class="col-12 col-sm-12 mb-3">
          <div class="row">
            <div class="col-6 col-md-3">
              <h5 class="head-title mt-2">Products</h5>
            </div>
            <div class="col-12 col-md-12 mt-3">
              <div class="row">
                <div class="col-md-3">




                </div>
                <div class="col-md-9 text-end">
                  <a href="/Products/create">
                    <button class="btn-sent-yellow-lg">+ Add new</button>
                  </a>
                </div>
              </div>

            </div>
          </div>


        </div>
        <table class="custom-table-bordered">
          <thead class="custom-thead-light">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Price</th>
            <th scope="col">Created at</th>
            <th scope="col">Action</th>
          </tr>
          </thead>
          <tbody>


          </tbody>
        </table>


      </div>

    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import Header from "@/components/Header";
import axios from "axios";

export default {
  name: "Products",
  data() {
    return {
      isLoading: false,
      fullPage: true,
      ResponseData:{}
    }
  },
  components: {
    Loading,
    Header
  },
  methods:{

    onCancel() {
    },
    EditClient(id){
      this.$router.push({ name: 'ClientEdit', params: { id: id  } })
    },

    DeleteBank(id){
      this.isLoading = true;
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.delete(process.env.VUE_APP_BACKEND_URL+'/auth/Banks/delete_by_id/'+id,{
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then(response => {
            this.isLoading = false;
            location.reload();
          });
    },
    getResults() {
      this.isLoading = true;
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.get(process.env.VUE_APP_BACKEND_URL+'/auth/Banks/get_all_banks',{
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then(response => {
            this.isLoading = false;
            this.ResponseData = response.data;
          });
    },
    EditBank(id){
      this.$router.push({ name: 'BankEdit', params: { id: id  } })
    },

  },

  mounted() {
    this.getResults();
    this.isLoading = true;

  }
}
</script>

<style scoped>

</style>