<template>
  <div>
  <loading :active.sync="isLoading"
           :can-cancel="true"
           :is-full-page="fullPage"></loading>

  <Header/>
  <div class="main_dashboard">

    <div class="sent_container mt-20">
      <div class="col-12 col-sm-12 mb-3">
        <div class="row">
          <div class="col-6 col-md-3">
            <h5 class="head-title mt-2">Create Client</h5>
          </div>

        </div>


        <div class="card mt-4">


          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(submitForm)" class="rowpt-70">
            <div class="row p-4">
            <div class="col-md-6">

              <label>Name</label>
              <ValidationProvider name="Name" rules="required" v-slot="{ errors }">
              <input type="text" v-model="form.name" class="lss-form-control" placeholder="Name">
              <span  class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

            <div class="col-md-6">
              <label>Taxcode</label>
              <ValidationProvider name="taxcode" rules="required|numeric" v-slot="{ errors }">
              <input type="number" v-model="form.taxcode" class="lss-form-control" maxlength="11" placeholder="Tax code">
                <span  class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>


            <div class="col-md-6 mt-3">
              <label>Email</label>
              <ValidationProvider name="email" rules="required|email" v-slot="{ errors }">
                <input type="text" v-model="form.email" class="lss-form-control" placeholder="Email">
                <span  class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

              <div class="col-md-6  mt-3">
                <label>Mobile</label>
                <ValidationProvider name="mobile" rules="required|numeric" v-slot="{ errors }">
                  <input type="number" v-model="form.mobile" class="lss-form-control" placeholder="Mobile">
                  <span  class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>


            <div class="col-md-6 mt-3">
              <label>Director Name</label>
              <ValidationProvider name="Mobile" rules="required" v-slot="{ errors }">
                <input type="text" class="lss-form-control" v-model="form.director_name" placeholder="Director Name">
                <span  class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

            <div class="col-md-6 mt-3">
              <label>Personal Id</label>
              <ValidationProvider name="Personal id" rules="required|numeric|max:11" v-slot="{ errors }">
                  <input type="text" class="lss-form-control" v-model="form.personal_id" placeholder="Personal Id">
                  <span  class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>


            <div class="col-md-6 mt-3">
              <label>Address</label>
              <ValidationProvider name="Address" rules="required" v-slot="{ errors }">
                  <input type="text" class="lss-form-control" v-model="form.address" placeholder="Address">
                  <span  class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

            <div class="col-md-6 mt-3">
              <label>Choose sms remainder</label>
              <ValidationProvider name="Sms remainder" rules="required" v-slot="{ errors }">
              <select class="lss-form-control" v-model="form.sms_check">
                  <option value="1">Yes</option>
                  <option value="0">No</option>
              </select>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

              <div class="col-md-6 mt-3">
                <label>Juridical Status</label>
                <ValidationProvider name="Juridical Status" rules="required" v-slot="{ errors }">
                  <select class="lss-form-control" v-model="form.status">
                    <option value="2">Juridical</option>
                    <option value="1">Physical</option>
                  </select>
                  <span  class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>

            <div class="col-md-12 mt-3 text-end">
              <button type="submit" class="btn-sent-yellow-lg" >+ Save</button>
            </div>


          </div>
          </form>
          </ValidationObserver>

        </div>


      </div>





    </div>

  </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import Header from "@/components/Header";
import axios from "axios";


export default {
  name: "Create",
  data() {
    return {
      isLoading: false,
      fullPage: true,
      error:false,
      errors:{},
      invalid:'',
      form:{
        name:'',
        taxcode:'',
        email:'',
        mobile:'',
        director_name:'',
        personal_id:'',
        address:'',
        sms_check:'',
        status:''
      }
    }
  },
  components: {
    Loading,
    Header
  },
  methods: {

    submitForm(){
      this.isLoading = true;
      const token = JSON.parse(localStorage.getItem('user')).access_token;

      axios.post(process.env.VUE_APP_BACKEND_URL+'/auth/client/add_client',{'data':this.form}, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then((res) => {
            this.isLoading = false;
            this.$router.push('/Clients');
          })
          .catch((error) => {
            this.isLoading = false;


          });

    },
    onFailure(message){
      this.error=true;
    },
    setErrors(errors){
      this.errors=errors;
    },
    hasError(fieldName){
      return (fieldName in this.errors);
    },
    clearError(event){
      delete this.errors[event.target.name]
    }


  },
  mounted() {
    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false
    }, 300)
  }
}
</script>

<style scoped>

</style>