<template>
  <div class="dashboard-menu fixed-top">
    <div class="sent_nav_bar_container">


      <div  class="pt-1">

        <b-navbar  toggleable="lg" >
          <b-navbar-brand href="#">
            <svg id="sent-logo" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="70%" viewBox="0 0 1458.983 601.032">
              <defs>
                <linearGradient id="linear-gradient" x1="0.5" y1="-0.807" x2="0.5" y2="1.855" gradientUnits="objectBoundingBox">
                  <stop offset="0" stop-color="#ffc400"/>
                  <stop offset="1" stop-color="#c822ff"/>
                </linearGradient>
                <linearGradient id="linear-gradient-2" y1="-0.016" y2="1.235" xlink:href="#linear-gradient"/>
                <linearGradient id="linear-gradient-3" y1="-0.212" y2="1.039" xlink:href="#linear-gradient"/>
                <linearGradient id="linear-gradient-4" x1="0.5" y1="-0.063" x2="0.5" y2="1.067" gradientUnits="objectBoundingBox">
                  <stop offset="0" stop-color="#ffc400"/>
                  <stop offset="1" stop-color="#e6abff"/>
                </linearGradient>
              </defs>
              <text id="SENT" transform="translate(465.983 342.032)" fill="#000" font-size="372" font-family="Arial-BoldMT, Arial" font-weight="700"><tspan x="0" y="0">SENT</tspan></text>
              <g id="icons8-synchronize" transform="translate(-4 -4.001)">
                <path id="Path_1" data-name="Path 1" d="M100.437,179.874A79.437,79.437,0,1,0,21,100.437,79.531,79.531,0,0,0,100.437,179.874Zm0-144.431a64.994,64.994,0,1,1-64.994,64.994A65.064,65.064,0,0,1,100.437,35.443Z" transform="translate(105.766 105.76)" fill="url(#linear-gradient)"/>
                <path id="Path_2" data-name="Path 2" d="M60.407,247.5a43.318,43.318,0,0,0-.022-82.607,150.641,150.641,0,0,1,138.6-110.042V76.52a13.95,13.95,0,0,0,7.1,12.19,14.546,14.546,0,0,0,14.537-.022l40.065-23.275a20.809,20.809,0,0,0-.007-36.173L220.617,5.965a14.523,14.523,0,0,0-14.537-.029,13.95,13.95,0,0,0-7.1,12.19V40.4A165,165,0,0,0,45.957,162.94a43.277,43.277,0,0,0,.014,86.514,165.833,165.833,0,0,0,64.691,92.616l8.319-11.807A151.34,151.34,0,0,1,60.407,247.5ZM253.418,41.726a6.366,6.366,0,0,1,.007,11.193l-40,23.181-.058-57.65ZM18.443,206.2a28.886,28.886,0,1,1,28.886,28.886A28.915,28.915,0,0,1,18.443,206.2Z" transform="translate(0 0)" fill="url(#linear-gradient-2)"/>
                <path id="Path_3" data-name="Path 3" d="M290.2,149.058A43.344,43.344,0,0,0,248.226,105.8a165.834,165.834,0,0,0-64.691-92.616l-8.319,11.807a151.39,151.39,0,0,1,58.574,82.766,43.321,43.321,0,0,0,.029,82.614,150.651,150.651,0,0,1-138.6,110.035V278.736a13.95,13.95,0,0,0-7.1-12.19,14.5,14.5,0,0,0-14.537.029L33.515,289.85a20.8,20.8,0,0,0,.007,36.166L73.58,349.29a14.546,14.546,0,0,0,14.537.022,13.93,13.93,0,0,0,7.1-12.183V314.851A165.013,165.013,0,0,0,248.247,192.323,43.356,43.356,0,0,0,290.2,149.058ZM80.772,336.768,40.779,313.529a6.366,6.366,0,0,1-.007-11.193l40-23.6Zm166.1-158.823a28.886,28.886,0,1,1,28.886-28.886A28.915,28.915,0,0,1,246.868,177.945Z" transform="translate(118.209 57.139)" fill="url(#linear-gradient-3)"/>
                <path id="Path_4" data-name="Path 4" d="M56.886,28A28.886,28.886,0,1,0,85.772,56.886,28.886,28.886,0,0,0,56.886,28Z" transform="translate(149.317 149.311)" fill="url(#linear-gradient-4)"/>
              </g>
            </svg>
          </b-navbar-brand>

          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="w-100">
              <b-nav-item href="/Home">Dashboard</b-nav-item>
              <b-nav-item href="/Clients">Clients</b-nav-item>
              <b-nav-item-dropdown>
                <template #button-content>
                  Invoices
                  <svg id="Component_2_1" data-name="Component 2 – 1" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
                    <g id="Group_4602" data-name="Group 4602">
                      <g id="Group_4604" data-name="Group 4604">
                        <g id="Rectangle_1003" data-name="Rectangle 1003" fill="#707070" stroke="#292929" stroke-width="2" opacity="0">
                          <rect width="15" height="15" stroke="none"/>
                          <rect x="1" y="1" width="13" height="13" fill="none"/>
                        </g>
                        <path id="Path_2" data-name="Path 2" d="M7.5,3.75,3.75,0,0,3.75" transform="translate(11.25 9.687) rotate(180)" fill="none" stroke="#292929" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                      </g>
                    </g>
                  </svg>

                </template>
                <b-dropdown-item href="/Invoices/create">Add Invoices</b-dropdown-item>
                <b-dropdown-item href="/Invoices">All Invoices</b-dropdown-item>
                <b-dropdown-item href="/Invoices/Archive">Archived Invoices</b-dropdown-item>
<!--                <b-dropdown-item href="#">Overdue Invoices</b-dropdown-item>
                <b-dropdown-item href="#">Archived Invoices</b-dropdown-item>-->
              </b-nav-item-dropdown>
              <b-nav-item href="/Products">Products</b-nav-item>


              <b-nav-item-dropdown>
                <template #button-content>
                  Settings
                  <svg id="Component_2_1" data-name="Component 2 – 1" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
                    <g id="Group_4602" data-name="Group 4602">
                      <g id="Group_4604" data-name="Group 4604">
                        <g id="Rectangle_1003" data-name="Rectangle 1003" fill="#707070" stroke="#292929" stroke-width="2" opacity="0">
                          <rect width="15" height="15" stroke="none"/>
                          <rect x="1" y="1" width="13" height="13" fill="none"/>
                        </g>
                        <path id="Path_2" data-name="Path 2" d="M7.5,3.75,3.75,0,0,3.75" transform="translate(11.25 9.687) rotate(180)" fill="none" stroke="#292929" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                      </g>
                    </g>
                  </svg>

                </template>
                <b-dropdown-item href="/Details">Details</b-dropdown-item>
                <b-dropdown-item href="/Banks">Account Numbers</b-dropdown-item>
                <b-dropdown-item href="/Users" v-if="role">Users</b-dropdown-item>
              </b-nav-item-dropdown>

            </b-navbar-nav>


                <span class="d-none d-md-block">


                  <svg  style="    margin-top: -5px;margin-right: 10px;" id="Component_3_1" data-name="Component 3 – 1" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16.5 17">
                    <path id="ic_notifications_none_24px" d="M9.744,16.5a1.44,1.44,0,0,0,1.436-1.436H8.308A1.44,1.44,0,0,0,9.744,16.5Zm4.308-4.308V8.6a4.367,4.367,0,0,0-3.231-4.537V3.577a1.077,1.077,0,1,0-2.154,0v.488A4.369,4.369,0,0,0,5.436,8.6v3.59L4,13.628v.718H15.487v-.718Zm-1.436.718H6.872V8.6A2.944,2.944,0,0,1,9.744,5.372,2.944,2.944,0,0,1,12.615,8.6Z" transform="translate(-4 0.5)"/>
                    <circle id="Ellipse_1" data-name="Ellipse 1" cx="5.5" cy="5.5" r="5.5" transform="translate(5.5)" fill="#ff7b7b"/>
                  </svg>
                </span>


            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto">

              <b-nav-item-dropdown right>
                <!-- Using 'button-content' slot -->

                <template #button-content>
                  {{ name }}
                  <svg id="Component_2_1" data-name="Component 2 – 1" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
                    <g id="Group_4602" data-name="Group 4602">
                      <g id="Group_4604" data-name="Group 4604">
                        <g id="Rectangle_1003" data-name="Rectangle 1003" fill="#707070" stroke="#292929" stroke-width="2" opacity="0">
                          <rect width="15" height="15" stroke="none"/>
                          <rect x="1" y="1" width="13" height="13" fill="none"/>
                        </g>
                        <path id="Path_2" data-name="Path 2" d="M7.5,3.75,3.75,0,0,3.75" transform="translate(11.25 9.687) rotate(180)" fill="none" stroke="#292929" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                      </g>
                    </g>
                  </svg>
                </template>
                <b-dropdown-item href="/Billing">Packages</b-dropdown-item>
                <b-dropdown-item href="/Profile">Profile</b-dropdown-item>
                <b-dropdown-item v-on:click="logout">Sign Out</b-dropdown-item>
              </b-nav-item-dropdown>
            </b-navbar-nav>
          </b-collapse>





        </b-navbar>
      </div>


    </div>



  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Header",
  data() {
    return {
      name:'',
      isActive:'',
      role:''
    }
  },
  mounted(){
    try {
      JSON.parse(localStorage.getItem('user'))

    }catch(e) {
      localStorage.removeItem('user');
      this.$router.push('/signin')
    }
    const user_info=JSON.parse(localStorage.getItem('user'));
    this.name=user_info.user.name;
    const token = JSON.parse(localStorage.getItem('user')).access_token;



    axios.post(process.env.VUE_APP_BACKEND_URL+'/auth/check-user',{'data':localStorage.getItem('user')}, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
        .then((res) => {

          if(res.data.active_status==='end_user' && this.$router.currentRoute.name!='Billing'){
            this.$router.push('/Billing')
          }
          if(res.data.role!=true && this.$router.currentRoute.name=='Users'){
            console.log(this.$router.currentRoute.name);
            this.$router.push('/Profile')
          }
          this.role=res.data.role;



        })
        .catch((error) => {

            localStorage.removeItem('user');
            this.$router.push('/signin')

        });



  },
  methods:{
    logout(){
      localStorage.removeItem('user');
      this.$router.push('/signin');
    }
  }
}
</script>

<style scoped>

</style>