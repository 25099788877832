var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('loading',{attrs:{"active":_vm.isLoading,"can-cancel":true,"is-full-page":_vm.fullPage},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('Header'),_c('div',{staticClass:"main_dashboard"},[_c('div',{staticClass:"sent_container mt-20"},[_c('div',{staticClass:"col-12 col-sm-12 mb-3"},[_vm._m(0),_c('div',{staticClass:"card mt-4"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"rowpt-70",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitForm)}}},[_c('div',{staticClass:"row p-4"},[_c('div',{staticClass:"col-md-6"},[_c('label',[_vm._v("Name")]),_c('ValidationProvider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.name),expression:"form.name"}],staticClass:"lss-form-control",attrs:{"type":"text","placeholder":"Name"},domProps:{"value":(_vm.form.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "name", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('label',[_vm._v("Taxcode")]),_c('ValidationProvider',{attrs:{"name":"taxcode","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.taxcode),expression:"form.taxcode"}],staticClass:"lss-form-control",attrs:{"type":"number","maxlength":"11","placeholder":"Tax code"},domProps:{"value":(_vm.form.taxcode)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "taxcode", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"col-md-6 mt-3"},[_c('label',[_vm._v("Email")]),_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],staticClass:"lss-form-control",attrs:{"type":"text","placeholder":"Email"},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "email", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"col-md-6  mt-3"},[_c('label',[_vm._v("Mobile")]),_c('ValidationProvider',{attrs:{"name":"mobile","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.mobile),expression:"form.mobile"}],staticClass:"lss-form-control",attrs:{"type":"number","placeholder":"Mobile"},domProps:{"value":(_vm.form.mobile)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "mobile", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"col-md-6 mt-3"},[_c('label',[_vm._v("Director Name")]),_c('ValidationProvider',{attrs:{"name":"Mobile","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.director_name),expression:"form.director_name"}],staticClass:"lss-form-control",attrs:{"type":"text","placeholder":"Director Name"},domProps:{"value":(_vm.form.director_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "director_name", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"col-md-6 mt-3"},[_c('label',[_vm._v("Personal Id")]),_c('ValidationProvider',{attrs:{"name":"Personal id","rules":"required|numeric|max:11"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.personal_id),expression:"form.personal_id"}],staticClass:"lss-form-control",attrs:{"type":"text","placeholder":"Personal Id"},domProps:{"value":(_vm.form.personal_id)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "personal_id", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"col-md-6 mt-3"},[_c('label',[_vm._v("Address")]),_c('ValidationProvider',{attrs:{"name":"Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.address),expression:"form.address"}],staticClass:"lss-form-control",attrs:{"type":"text","placeholder":"Address"},domProps:{"value":(_vm.form.address)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "address", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"col-md-6 mt-3"},[_c('label',[_vm._v("Choose sms remainder")]),_c('ValidationProvider',{attrs:{"name":"Sms remainder","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.sms_check),expression:"form.sms_check"}],staticClass:"lss-form-control",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "sms_check", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"1"}},[_vm._v("Yes")]),_c('option',{attrs:{"value":"0"}},[_vm._v("No")])]),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"col-md-6 mt-3"},[_c('label',[_vm._v("Juridical Status")]),_c('ValidationProvider',{attrs:{"name":"Juridical Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.status),expression:"form.status"}],staticClass:"lss-form-control",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "status", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"2"}},[_vm._v("Juridical")]),_c('option',{attrs:{"value":"1"}},[_vm._v("Physical")])]),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"col-md-12 mt-3 text-end"},[_c('button',{staticClass:"btn-sent-yellow-lg",attrs:{"type":"submit"}},[_vm._v("+ Save")])])])])]}}])})],1)])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6 col-md-3"},[_c('h5',{staticClass:"head-title mt-2"},[_vm._v("Create Client")])])])}]

export { render, staticRenderFns }