<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="true"
             :on-cancel="onCancel"
             :is-full-page="fullPage"></loading>

    <Header/>
    <div class="main_dashboard">

      <div class="sent_container mt-20">
        <div class="col-12 col-sm-12 mb-3">
          <div class="row">
            <div class="col-6 col-md-3">
              <h5 class="head-title mt-2">Bank edit</h5>
            </div>

          </div>

          <div class="card mt-4">

            <ValidationObserver v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(submitForm)" class="rowpt-70">
              <div class="row p-4">
                <div class="col-md-6">
                  <label>Company</label>
                  <ValidationProvider name="company" rules="required" v-slot="{ errors }">
                    <v-select v-model="form.company" class="lss-form-select" placeholder="Choose Company" label="text" :options="CompanyData"></v-select>
                    <span  class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>

                <div class="col-md-6">
                  <label>Bank name</label>
                  <ValidationProvider name="bank_name" rules="required" v-slot="{ errors }">
                    <input type="text" v-model="form.bank_name" class="lss-form-control" placeholder="Bank name">
                    <span  class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>

                <div class="col-md-6 mt-3">
                  <label>Bank IBAN</label>
                  <ValidationProvider name="bank_iban" rules="required" v-slot="{ errors }">
                  <input type="text" v-model="form.bank_iban" class="lss-form-control" placeholder="Bank IBAN">
                    <span  class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>

                <div class="col-md-6 mt-3">
                  <label>Bank Code</label>
                  <ValidationProvider name="bank_code" rules="required" v-slot="{ errors }">
                  <input type="text" v-model="form.bank_code" class="lss-form-control" placeholder="Bank Code">
                    <span  class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>









                <div class="col-md-12 mt-3 text-end">
                  <button class="btn-sent-yellow-lg">+ Save</button>
                </div>


              </div>
            </form>
            </ValidationObserver>

          </div>


        </div>



      </div>

    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import Header from "@/components/Header";
import axios from "axios";

export default {
  name: "BankEdit",
  data() {
    return {
      isLoading: false,
      fullPage: true,
      form:{
        company:'',
        bank_name:'',
        bank_iban:'',
        bank_code:'',

      },
      CompanyData:[],
    }
  },
  components: {
    Loading,
    Header
  },
  methods:{
    submitForm(){
      this.isLoading = true;
      const token = JSON.parse(localStorage.getItem('user')).access_token;

      axios.put(process.env.VUE_APP_BACKEND_URL+'/auth/Banks/update_bank/'+this.$route.params.id,{'data':this.form}, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then((res) => {
            this.isLoading = false;
            this.$router.push('/Banks');
          })
          .catch((error) => {
            this.isLoading = false;


          });

    },
    onCancel() {
    },
    getClients(){

      this.isLoading = true;
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.get(process.env.VUE_APP_BACKEND_URL+'/auth/client/get_options_all_sender',{
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then(response => {
            this.isLoading = false;
            this.CompanyData = response.data;
          });
    },
    getDataById(){
      this.isLoading = true;
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.get(process.env.VUE_APP_BACKEND_URL+'/auth/Banks/get_by_id/'+this.$route.params.id, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then((res) => {
            this.isLoading = false;
            console.log(res.data);
            this.form=res.data;
          })
          .catch((error) => {
            this.isLoading = false;


          });
    }


  },

  mounted() {
    this.getDataById();
    this.getClients();
    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false
    }, 300)
  }
}
</script>

<style scoped>

</style>