var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('loading',{attrs:{"active":_vm.isLoading,"can-cancel":true,"on-cancel":_vm.onCancel,"is-full-page":_vm.fullPage},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('Header'),_c('div',{staticClass:"main_dashboard"},[_c('div',{staticClass:"sent_container mt-20"},[_c('div',{staticClass:"col-12 col-sm-12 mb-3"},[_vm._m(0),_c('div',{staticClass:"card mt-4"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"rowpt-70",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitForm)}}},[_c('div',{staticClass:"row p-4"},[_c('div',{staticClass:"col-md-6"},[_c('label',[_vm._v("Company")]),_c('ValidationProvider',{attrs:{"name":"company","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"lss-form-select",attrs:{"placeholder":"Choose Company","label":"text","options":_vm.CompanyData},model:{value:(_vm.form.company),callback:function ($$v) {_vm.$set(_vm.form, "company", $$v)},expression:"form.company"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('label',[_vm._v("Bank name")]),_c('ValidationProvider',{attrs:{"name":"bank_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.bank_name),expression:"form.bank_name"}],staticClass:"lss-form-control",attrs:{"type":"text","placeholder":"Bank name"},domProps:{"value":(_vm.form.bank_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "bank_name", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"col-md-6 mt-3"},[_c('label',[_vm._v("Bank IBAN")]),_c('ValidationProvider',{attrs:{"name":"bank_iban","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.bank_iban),expression:"form.bank_iban"}],staticClass:"lss-form-control",attrs:{"type":"text","placeholder":"Bank IBAN"},domProps:{"value":(_vm.form.bank_iban)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "bank_iban", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"col-md-6 mt-3"},[_c('label',[_vm._v("Bank Code")]),_c('ValidationProvider',{attrs:{"name":"bank_code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.bank_code),expression:"form.bank_code"}],staticClass:"lss-form-control",attrs:{"type":"text","placeholder":"Bank Code"},domProps:{"value":(_vm.form.bank_code)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "bank_code", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"col-md-12 mt-3 text-end"},[_c('button',{staticClass:"btn-sent-yellow-lg"},[_vm._v("+ Save")])])])])]}}])})],1)])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6 col-md-3"},[_c('h5',{staticClass:"head-title mt-2"},[_vm._v("Bank edit")])])])}]

export { render, staticRenderFns }