import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Signin from '../views/auth/Signin.vue'
import Signup from '../views/auth/Signup.vue'
import Recover from '../views/auth/Recover.vue'
import Dashboard from '../views/auth/Home.vue'
import Clients from '../views/auth/pages/Clients/Clients.vue'
import ClientCreate from '../views/auth/pages/Clients/Create.vue'
import ClientEdit from '../views/auth/pages/Clients/Edit.vue'
import Profile from '../views/auth/pages/profile/Profile.vue'
import Invoices from '../views/auth/pages/Invoices/index.vue'
import ArchiveInvoices from '../views/auth/pages/Invoices/Archive.vue'
import InvoiceCreate from '../views/auth/pages/Invoices/Create.vue'
import InvoiceEdit from '../views/auth/pages/Invoices/Edit.vue'
import Details from '../views/auth/pages/Details/Index.vue'
import DetailsCreate from '../views/auth/pages/Details/Create.vue'
import DetailsEdit from '../views/auth/pages/Details/Edit.vue'
import Banks from '../views/auth/pages/Bank/Index.vue'
import BankCreate from '../views/auth/pages/Bank/Create.vue'
import BankEdit from '../views/auth/pages/Bank/Edit.vue'
import Products from '../views/auth/pages/Products/Index.vue'
import Billing from '../views/auth/pages/Billing/Index.vue'
import Users from '../views/auth/pages/Users/Index.vue'
import CreateUser from '../views/auth/pages/Users/Create.vue'
import UserEdit from '../views/auth/pages/Users/Edit.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Signin
  },

  {
    path: '/Signin',
    name: 'Signin',
    component: Signin
  },
  {
    path: '/Signup',
    name: 'Signup',
    component: Signup
  },
  {
    path: '/recover-password',
    name: 'Recover',
    component: Recover
  },
  {
    path:'/Home',
    name:'Dashboard',
    component: Dashboard
  },
  {
    path:'/Clients',
    name:'Clients',
    component: Clients
  },
  {
    path:'/Clients/Create',
    name:'ClientCreate',
    component: ClientCreate
  },
  {
    path:'/Profile',
    name:Profile,
    component: Profile
  },

  {
    path:'/Clients/:id/edit',
    name:'ClientEdit',
    component: ClientEdit
  },
  {
    path:'/Invoices',
    name:'Invoices',
    component: Invoices
  },
  {
    path:'/Invoices/Archive',
    name:'ArchiveInvoices',
    component: ArchiveInvoices
  },
  {
    path:'/Invoices/create',
    name:'InvoiceCreate',
    component: InvoiceCreate
  },
  {
    path:'/Invoices/:id/edit',
    name:'InvoiceEdit',
    component: InvoiceEdit
  },
  {
    path:'/Details',
    name:'Details',
    component: Details
  },
  {
    path:'/Details/create',
    name:'DetailsCreate',
    component: DetailsCreate
  },
  {
    path:'/Details/:id/edit',
    name:'DetailsEdit',
    component: DetailsEdit
  },
  {
    path:'/Banks',
    name:"Banks",
    component: Banks
  },
  {
    path:'/Banks/create',
    name:"BankCreate",
    component: BankCreate
  },
  {
    path:'/Banks/:id/edit',
    name:"BankEdit",
    component: BankEdit
  },
  {
    path:'/Products',
    name:"Products",
    component: Products
  },
  {
    path:'/Billing',
    name:"Billing",
    component: Billing
  },
  {
    path:'/Users',
    name:"Users",
    component: Users
  },
  {
    path:'/Users/create',
    name:"CreateUser",
    component: CreateUser
  },
  {
    path:'/Users/:id/edit',
    name:'UserEdit',
    component: UserEdit
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  if (to.name === 'Signin' || to.name==='Signup' || to.name==='Recover') {
    if(localStorage.getItem('user')){
      window.location.href='/Home';
    }else{
      next() // login route is always  okay (we could use the requires auth flag below). prevent a redirect loop
    }
  } else if (to.meta && to.meta.requiresAuth === false) {
    next() // requires auth is explicitly set to false
  } else if (localStorage.getItem('user')) {

    next() // i'm logged in. carry on
  } else {
    if(to.name==='Signup'){
      next({ name: 'Signup' }) // always put your redirect as the default case
    }else{
      next({ name: 'Signin' }) // always put your redirect as the default case
    }

  }
})

export default router
